export const formatTime = (time) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};

export const getTime = (timestamp, format = 'yyyy-MM-dd') => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const second = date.getSeconds();

  return format
    .replace('yyyy', `${year}`)
    .replace('MM', `${formatTime(month)}`)
    .replace('dd', `${formatTime(day)}`)
    .replace('HH', `${formatTime(hours)}`)
    .replace('mm', `${formatTime(minutes)}`)
    .replace('ss', `${formatTime(second)}`);
};

export const getUTC8Time = () => {
  let now = new Date().getTime();
  let offset = new Date().getTimezoneOffset();
  // 格林尼治时间
  let GMT = now + offset * 60 * 1000;
  // 返回UTC+8的时间
  let UTC = 8;
  return GMT + UTC * 60 * 60 * 1000;
};

export const getUTC0Time = () => {
  const now = new Date().getTime();
  const offset = new Date().getTimezoneOffset();
  // 格林尼治时间
  const GMT = now + offset * 60 * 1000;
  // 返回UTC+0的时间
  const UTC = 0;
  return GMT + UTC * 60 * 60 * 1000;
};
