/* eslint-disable max-statements */
/* eslint-disable filenames/match-exported */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Tea } from '@fe/game-sdk';
import './index.less';
import { getHeaderLinkConfig, getHeaderTopUpConfig } from '../../common/services/siteData';
import Modal from '../modal';
import { getUTC0Time } from '../../utils/getTime';
import { customRedirect } from '../../utils/link/goPage';
import { getSpecFont } from '../../utils/fontClass';
import { hackLangLength } from './tools';
import { useLang, LANGs, lang2RedeemMap } from '~/common/i18n';

/* 内容站点配置示例
{
	"append": [{
		"type": "link",
		"key": "leaderboard",
		"link": "https://marvelsnap.com/"
	},{
    "type": "nav",
		"key": "game2",
    "child": [
      {
		    "key": "how_to_play",
		    "link": "https://marvelsnap.com"
	    }
    ]
  }],
	"game": [{
		"key": "how_to_play",
		"link": "https://marvelsnap.com"
	}, {
		"key": "cards_and_locations",
		"link": "https://www.marvelsnap.com"
	}]
} 
*/
export default function HeaderForPC() {
  const { t, i18n } = useTranslation();
  const { languageLabel, changeLanguage, language } = useLang();
  const [appendData, setAppendData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const langListDom = useRef(null);
  const [showDownload, setShowDownload] = useState(false);
  const [showShopPop, setShowShopPop] = useState(false);
  const [rechargeLink, setrechargeLink] = useState('');

  const goNews = useCallback(() => {
    customRedirect('./newslist');
  }, []);

  const reportTea = (system) => {
    Tea.sendLogV3('acv_button_touch', {
      component_text: `${system}_top_${i18n.language}`,
      is_score: 1,
      process_type: 999,
      page_text: 'download',
      component_name: 'be_null',
      component_id: 'be_null'
    });
  };

  const backToHome = useCallback(() => {
    customRedirect('./');
  }, []);

  const handleLangChange = useCallback((item) => {
    langListDom.current.style.opacity = 0;
    langListDom.current.style.transform = 'scale(0)';
    setTimeout(() => {
      langListDom.current.style = '';
    }, 1000);
    if (item.value === i18n.language) {
      return;
    }
    changeLanguage(item.value);
    // 判断是否在新闻详情页面
    if (location.pathname.indexOf('newsdetail') > -1) {
      setTimeout(() => {
        customRedirect('./');
      }, 200);
    }
  }, []);

  const goHowToPlay = useCallback(() => {
    customRedirect('./howtoplay');
  }, []);

  const goLeaderBoard = useCallback(() => {
    customRedirect('https://www.marvelsnap.com/infiniteleaderboard', true);
  }, []);

  const goBattleRecapWithS10 = () => {
    window.open('https://www.marvelsnap.com/battlerecap/s10?from_source=site');
  };

  const goBattleRecapWithS11 = useCallback(() => {
    window.open('https://www.marvelsnap.com/battlerecap/s11?from_source=site');
  }, []);

  // 获取动态菜单
  useEffect(() => {
    getHeaderLinkConfig().then((data) => {
      if (!data) {
        return;
      }
      // append内的是一级数据，game内的是game下的菜单数据。
      const { append, game } = data;
      if (append) {
        setAppendData(append);
      }
      if (game) {
        setGameData(game);
      }
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line promise/prefer-await-to-then
    getHeaderTopUpConfig({ language: i18n.language }).then((data) => {
      setrechargeLink(data || '');
    });
  }, [i18n.language]);

  useEffect(() => {
    // 获取当前日期和时间
    const nowTimeStamp = getUTC0Time();
    const now = new Date(nowTimeStamp);
    const currentDate = now.toDateString();
    const currentHour = now.getHours();

    console.log(currentDate, currentHour, '[currentHour]');

    // 从本地存储中获取上次弹出弹窗的日期
    const lastPopupDate = localStorage.getItem('lastPopupDate');

    // 检查是否到了刷新时间（19:00）
    if (currentHour >= 19) {
      // 如果已经过了 19:00，将本地存储中的日期设置为下一天
      const nextDay = new Date(now);
      nextDay.setDate(nextDay.getDate() + 1);
      const nextDayDate = nextDay.toDateString();
      localStorage.setItem('lastPopupDate', nextDayDate);
    }

    // 检查是否需要弹出弹窗
    if (lastPopupDate !== currentDate) {
      setShowShopPop(true);
      // 更新本地存储中的日期
      // localStorage.setItem('lastPopupDate', currentDate);
    }
  }, []);

  const handleClickGo = () => {
    Tea.sendLogV3('acv_button_touch', {
      component_text: 'everyDay_go',
      is_score: 1,
      process_type: 999,
      page_text: 'everyDay_go',
      component_name: 'be_null',
      component_id: 'be_null'
    });
    const nowTimeStamp = getUTC0Time();
    const now = new Date(nowTimeStamp);
    const currentDate = now.toDateString();
    localStorage.setItem('lastPopupDate', currentDate);
    setShowShopPop(false);
    openRechargeLink();
  };

  const openRechargeLink = () => {
    window.open(rechargeLink);
  };

  return (
    <div styleName="pc-header" className={getSpecFont(i18n.language)}>
      <div styleName="left-area">
        <div styleName="sub-nav">
          <span>{t('game')}</span>
          <div styleName="sub-nav-wrap">
            <div>
              <span styleName="hover-nav" onClick={goHowToPlay}>
                {t('how_to_play')}
              </span>
            </div>
            <div>
              <span styleName="hover-nav" onClick={goLeaderBoard}>
                {t('leader_board')}
              </span>
            </div>
            {/* <div styleName="sub-2nd-nav">
              <span styleName="hover-nav">{t('battle_recap')}</span>
              <div styleName="sub-2nd-nav-wrap">
                <div>
                  <span styleName="hover-nav" onClick={goBattleRecapWithS10}>
                    {t('days_of_future')}
                  </span>
                </div>
                <div>
                  <span styleName="hover-nav" onClick={goBattleRecapWithS11}>
                    {t('animals_assemble_season')}
                  </span>
                </div>
              </div>
            </div> */}
            {gameData.map((item) => (
              <div key={item.key}>
                <a href={item.link} target="_blank" styleName="hover-nav">
                  {t(item.key)}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div styleName="hover-nav" onClick={goNews}>
          {t('news')}
        </div>
        <div
          styleName="hover-nav"
          onClick={() => {
            window.open(
              `https://pay-va.nvsgames.com/topup/262304/${
                lang2RedeemMap[language] || 'us-en'
              }#tab=cdk`
            );
          }}
        >
          {t('redeem_code')}
        </div>
        <div
          styleName="hover-nav"
          onClick={() => {
            window.open('https://www.marvelsnap.com/creators');
          }}
        >
          {t('creators_link')}
        </div>
        <>
          {appendData.map((item, index) => {
            if (item.type === 'nav') {
              // 二级菜单类型
              return (
                <div styleName="sub-nav" key={index}>
                  <span>{t(item.key)}</span>
                  <div styleName="sub-nav-wrap">
                    {item.child.map((item) => (
                      <div key={item.key}>
                        <a href={item.link} target="_blank" styleName="hover-nav">
                          {t(item.key)}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              );
            }
            // link类型
            return (
              <a href={item.link} key={index} styleName="hover-nav" target="_blank">
                {t(item.key)}
              </a>
            );
          })}
        </>
      </div>
      <div styleName="center-logo" onClick={backToHome}></div>
      <div styleName="right-area">
        <div styleName="language-nav sub-nav">
          {/* starling-disable-next-line */}
          <span>{languageLabel}</span>
          <div styleName="sub-nav-wrap" ref={langListDom}>
            {LANGs.map((item, index) => (
              <div
                key={index}
                styleName="center-text"
                className={getSpecFont(item.value)}
                onClick={() => handleLangChange(item)}
              >
                <span styleName="hover-nav">{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        {/* <div styleName="img-icon head-login">{t('log_in')}</div> */}
        <span styleName="img-icon head-download" onClick={() => setShowDownload(true)}>
          <span style={hackLangLength(i18n.language)}>{t('download')}</span>
        </span>
        {rechargeLink && (
          <span styleName="img-icon head-regressive" onClick={openRechargeLink}>
            <span style={hackLangLength(i18n.language)}>{t('top_up')}</span>
          </span>
        )}
      </div>

      {/* 下载弹窗 */}
      <Modal show={showDownload} onClose={() => setShowDownload(false)}>
        <div styleName="download">
          <p styleName="down-title">{t('download_marvel_snap')}</p>
          <div styleName="qrcode"></div>
          <div styleName="down-links">
            <div styleName="down-a">
              <a
                href="https://play.google.com/store/apps/details?id=com.nvsgames.snap"
                styleName="google"
                target="_blank"
                onClick={() => reportTea('android')}
              ></a>
            </div>
            <div styleName="down-a">
              <a
                href="https://apps.apple.com/app/id1592081003"
                styleName="appstore"
                target="_blank"
                onClick={() => reportTea('ios')}
              ></a>
            </div>
            <div styleName="down-a">
              <a
                href="https://store.steampowered.com/app/1997040/"
                styleName="windows"
                target="_blank"
                onClick={() => reportTea('pc')}
              ></a>
            </div>
          </div>
        </div>
      </Modal>

      {/* 商城弹窗 */}
      <Modal show={showShopPop} onClose={() => setShowShopPop(false)} showClose={false}>
        <div styleName="shop-pop">
          <div styleName="text1">{t('Tuesday_body1')}</div>
          <div styleName="text2">{t('Tuesday_body2')}</div>
          <div styleName="btn" onClick={handleClickGo}>
            {t('Tuesday_go')}
          </div>
        </div>
      </Modal>
    </div>
  );
}
